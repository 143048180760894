<template>
  <div class="home">
    <div class="nav-box">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="(item, index) in classList" :key="index">
          <div class="nav-item" @click="openClass(item.fk_category_id)">
            <img class="nav-item-img" :src="item.img" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="banner">
      <el-carousel
        trigger="click"
        height="300px"
        indicator-position="none"
        @change="bannerChange"
        ref="bannerRef"
        :interval="4000"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img
            class="banner-img"
            :src="item.img"
            alt=""
            @click="bannerOpen(item.url)"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-dot-box" v-if="bannerList.length > 0">
        <div
          v-for="(item, idx) in bannerList.length"
          :key="idx"
          @click="changedot(index)"
          :class="[idx == bannerCurrent ? 'active' : 'dot-item']"
        ></div>
      </div>
    </div>
    <div class="research">
      <div class="title-box">
        <div class="title-name">教研课程</div>
        <div class="title-more" @click="onClick('teacher_study')">
          <span>更多</span>
          <img class="more-icon" src="../../assets/home/ic_6.png" alt="" />
        </div>
      </div>
      <div class="research-box" v-if="teachingList.left[0]">
        <div
          class="box-left"
          @click="openDetail(teachingList.left[0].fk_course_id)"
          v-if="teachingList.left.length > 0"
        >
          <div class="left-img-box">
            <img
              class="left-img"
              :src="teachingList.left[0].img || kong"
              alt=""
            />
            <div class="mask">
              <img class="mask-icon" src="../../assets/home/ic_1.png" alt="" />
            </div>
          </div>
          <div class="left-content">
            <div class="content-title line-2">
              {{ teachingList.left[0].name || "" }}
            </div>
            <div class="content-introduction line-2">
              {{ teachingList.left[0].remark || "" }}
            </div>
            <div class="content-bottom">
              <div class="people-num">
                参与学习人数{{ teachingList.left[0].read_num || "" }}
              </div>
              <div class="curriculum-num">
                课时数{{ teachingList.left[0].course_chapter_count || "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="box-right">
          <div class="right-list">
            <div
              class="right-item"
              @click="openDetail(item.fk_course_id)"
              v-for="(item, index) in teachingList.right"
              :key="index"
              :style="{ 'margin-bottom': index < 2 ? '12px' : '' }"
            >
              <div class="right-img-box">
                <img class="right-img" :src="item.img || kong" alt="" />
                <div class="mask">
                  <img
                    class="mask-icon"
                    src="../../assets/home/ic_1.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="right-content">
                <div class="r-content-title line-2">
                  {{ item.name || 0 }}
                </div>
                <div class="r-content-bottom">
                  <div class="r-people-num">
                    参与学习人数{{ item.read_num || 0 }}
                  </div>
                  <div class="r-curriculum-num">
                    课时数{{ item.course_chapter_count || 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="live">
      <div class="title-box">
        <div class="title-name">直播课程</div>
        <div class="title-more" @click="onClick('teacher_live')">
          <span>更多</span>
          <img class="more-icon" src="../../assets/home/ic_6.png" alt="" />
        </div>
      </div>
      <div class="live-list" v-if="liveList.length > 0">
        <div
          class="live-item"
          v-for="(item, index) in liveList"
          :key="item.fk_course_id"
          :style="{ 'margin-bottom': index < 4 ? '12px' : '' }"
          @click="openDetail(item.fk_course_id)"
        >
          <div class="live-item-img-box">
            <img class="live-item-img" :src="item.img || kong" alt="" />
            <div class="tip" v-if="item.live_status == 2">直播中</div>
            <div class="mask">
              <img class="mask-icon" src="../../assets/home/ic_1.png" alt="" />
            </div>
          </div>
          <div class="live-item-content">
            <div class="live-content-title line-2">
              {{ item.name }}
            </div>
            <div class="live-time">
              {{ item.live_status != 3 ? "开播时间" : "结束时间" }}：{{
                item.startTime
              }}
            </div>
          </div>
        </div>
        <div
          class="live-item"
          style="background-color: transparent"
          v-for="item in 4 - (liveList.length % 4)"
          :key="item"
        ></div>
      </div>
    </div>
    <div class="notice">
      <div class="title-box">
        <div class="title-name">信息资讯</div>
      </div>
      <div class="notice-list">
        <div class="list-box">
          <div class="notice-title-box">
            <div class="notice-title">通知公告</div>
            <div class="notice-more" @click="onClick('news')">
              <span>更多</span>
              <img
                class="notice-more-icon"
                src="../../assets/home/ic_6.png"
                alt=""
              />
            </div>
          </div>
          <div class="notice-content-list" v-if="NoticeList.length > 0">
            <div
              class="n-l-item"
              @click="openNoticeDetail(item.fk_news_id)"
              v-for="(item, index) in NoticeList"
              :key="index"
            >
              <div class="n-l-item-title line-1">
                {{ item.name }}
              </div>
              <div class="n-l-item-time">{{ item.date_creation }}</div>
            </div>
          </div>
        </div>
        <div class="list-box">
          <div class="notice-title-box">
            <div class="notice-title">会议发布</div>
            <div class="notice-more" @click="onClick('news')">
              <span>更多</span>
              <img
                class="notice-more-icon"
                src="../../assets/home/ic_6.png"
                alt=""
              />
            </div>
          </div>
          <div class="notice-content-list" v-if="meetingList.length > 0">
            <div
              class="n-l-item"
              @click="openNoticeDetail(item.fk_news_id)"
              v-for="(item, index) in meetingList"
              :key="index"
            >
              <div class="n-l-item-title">
                {{ item.name }}
              </div>
              <div class="n-l-item-time">{{ item.date_creation }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="resourcesShare">
      <div class="title-box">
        <div class="title-name">资源分享</div>
        <div class="title-more" @click="onClick('resourse')">
          <span>更多</span>
          <img class="more-icon" src="../../assets/home/ic_6.png" alt="" />
        </div>
      </div>
      <div class="resourcesShare-list" v-if="researchList.length > 0">
        <div
          @click="openShareDetail(item.fk_resource_id)"
          class="resourcesShare-item"
          v-for="(item, index) in researchList"
          :key="index"
        >
          <div class="resourcesShare-left">
            <img class="left-img" :src="item.img || kong" alt="" />
            <!-- <div class="mask">
              <img class="mask-icon" src="../../assets/home/ic_1.png" alt="" />
            </div> -->
          </div>
          <div class="resourcesShare-right">
            <div class="right-title line-2">{{ item.name }}</div>
            <div class="right-introduction line-2">简介：{{ item.remark }}</div>
            <div class="right-bottom">
              <div class="bottom-type">
                类型：{{ item.type | resourcesShareType }}
              </div>
              <div class="bottom-num-box">
                <div class="views-num">浏览量：{{ item.read_num }}</div>
                <div class="dowload-num">下载量：{{ item.down_num }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";
export default {
  name: "Home",
  data() {
    return {
      bannerList: [],
      bannerCurrent: 0,
      researchList: [],
      teachingList: {
        left: [],
        right: [],
      },
      liveList: [],
      NoticeList: [],
      meetingList: [],
      resourcesList: [],
      classList: [],
      swiperOption: {
        slidesPerView: "5",
      },
    };
  },
  mounted() {
    this.initPage();
    this.getTitle(1);
  },
  filters: {
    resourcesShareType(type) {
      switch (type) {
        case "1":
          return "教研课堂";
        case "2":
          return "教学设计";
        case "3":
          return "教研成果";
        case "4":
          return "教研资源";
        default:
          return "暂无";
      }
    },
  },
  methods: {
    initPage() {
      Promise.all([
        Api.Banner({
          type: 1,
          page: 1,
          pageSize: 12,
        }),
        Api.Course({
          type: 1,
          is_red: 2,
          page: 1,
          fk_category_id: 0,
          fk_grade_id: 0,
          fk_subject_id: 0,
          keywords: "",
          pageSize: 5,
        }),
        Api.Course({
          type: 3,
          is_red: 2,
          page: 1,
          fk_category_id: 0,
          fk_grade_id: 0,
          fk_subject_id: 0,
          keywords: "",
          pageSize: 8,
        }),
        Api.News({
          type: 1,
          page: 1,
          keywords: "",
          pageSize: 9,
        }),
        Api.News({
          type: 2,
          page: 1,
          keywords: "",
          pageSize: 9,
        }),
        Api.Resource({
          type: 99,
          page: 1,
          fk_category_id: 0,
          fk_grade_id: 0,
          fk_subject_id: 0,
          keywords: "",
          pageSize: 8,
        }),
        Api.Category({
          fk_business_id: this.$store.state.businessId,
          type: 1,
        }),
      ])
        .then((res) => {
          this.bannerList = res[0].data;
          if (res[1].data) {
            this.teachingList.left.push(res[1].data[0]);
            res[1].data.forEach((item, index) => {
              if (index > 0) {
                this.teachingList.right.push(item);
              }
            });
          }
          this.liveList = res[2].data;
          this.NoticeList = res[3].data;
          this.meetingList = res[4].data;
          this.researchList = res[5].data;
          this.classList = res[6].data;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    onClick(url, query = {}) {
      this.$router.push({
        name: url,
        query,
      });
    },
    bannerOpen(val) {
      let url = "";
      let num = "";
      if (val.indexOf("?") != -1) {
        let arr = val.split("?");
        url = arr[0];
        num = arr[1];
      } else {
        url = val;
      }

      this.$router.push({
        name: url,
        query: {
          id: num ? num : undefined,
        },
      });
    },
    openClass(type) {
      this.$router.push({
        name: "teacher_study",
        query: {
          classID: type,
        },
      });
    },
    openDetail(id) {
      this.$router.push({
        name: "study_detail",
        query: {
          id,
        },
      });
    },
    openNoticeDetail(id) {
      this.$router.push({
        name: "news_detail",
        query: {
          id,
        },
      });
    },
    openShareDetail(id) {
      this.$router.push({
        name: "resourse_detail",
        query: {
          id,
        },
      });
    },
    bannerChange(index) {
      this.bannerCurrent = index;
    },
    changedot(idx) {
      this.$refs.bannerRef.setActiveItem(idx);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-carousel__arrow--left {
  width: 50px;
  height: 50px;
}
::v-deep .el-carousel__arrow--right {
  width: 50px;
  height: 50px;
}
::v-deep .el-icon-arrow-left {
  font-size: 18px;
}
::v-deep .el-icon-arrow-right {
  font-size: 18px;
}
.nav-box {
  width: 1200px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  .swiper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #000000;
      padding: 20px;
      cursor: pointer;
      .nav-item-img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

.home {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  .title-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 26px;
    .title-name {
      font-size: 24px;
      font-weight: 400;
      color: #000000;
    }
    .title-more {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      .more-icon {
        width: 6px;
        height: 10px;
        margin-left: 6px;
      }
    }
  }
  .banner {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .banner-img {
      height: 100%;
      cursor: pointer;
    }
    .banner-dot-box {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      .dot-item {
        width: 16px;
        height: 4px;
        border-radius: 2px;
        display: inline-block;
        background: #fff;
        opacity: 0.4;
        cursor: pointer;
        margin-right: 6px;
      }
      .active {
        width: 16px;
        height: 4px;
        border-radius: 2px;
        display: inline-block;
        background: #fff;
        opacity: 1;
        cursor: pointer;
        margin-right: 6px;
      }
    }
  }
  .research {
    width: 100%;
    .research-box {
      width: 100%;
      display: flex;
      .box-left {
        width: 594px;
        margin-right: 12px;
        border-radius: 10px;
        background-color: #fff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .left-img-box {
          width: 100%;
          height: 380px;
          position: relative;
          overflow: hidden;
          .left-img {
            height: 100%;
            object-fit: cover;
          }
          &:hover .mask {
            opacity: 1;
          }
          .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0;
            transition: opacity 0.5s ease;
            position: absolute;
            top: 0;
            left: 0;
            .mask-icon {
              width: 104px;
              height: 104px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .left-content {
          flex: 1;
          padding: 0 16px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .content-title {
            width: 100%;
            font-size: 22px;
            font-weight: 400;
            color: #000000;
            padding: 30px 0;
            letter-spacing: 1px;
          }
          .content-introduction {
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .content-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            margin-top: auto;
            border-top: 1px solid #ececec;
            .people-num {
              font-size: 18px;
              font-weight: 400;
              color: #999999;
            }
            .curriculum-num {
              font-size: 18px;
              font-weight: 400;
              color: #1d2088;
            }
          }
        }
      }
      .box-right {
        width: calc(1200px - 606px);
        cursor: pointer;
        .right-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .right-item {
            width: calc(50% - 6px);
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .right-img-box {
              width: 100%;
              height: 174px;
              position: relative;
              .right-img {
                height: 100%;
              }
              &:hover .mask {
                opacity: 1;
              }
              .mask {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                opacity: 0;
                transition: opacity 0.5s ease;
                position: absolute;
                top: 0;
                left: 0;
                .mask-icon {
                  width: 60px;
                  height: 60px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
            .right-content {
              flex: 1;
              display: flex;
              flex-direction: column;
              padding: 14px 10px;
              box-sizing: border-box;
              overflow: hidden;
              .r-content-title {
                flex: 1;
                font-size: 16px;
                font-weight: 400;
                color: #000000;
                padding-bottom: 36px;
                letter-spacing: 1px;
                line-height: 22px;
                margin: auto 0;
              }
              .r-content-bottom {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 14px;
                border-top: 1px solid #ececec;
                margin-top: auto;
                .r-people-num {
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                }
                .r-curriculum-num {
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d2088;
                }
              }
            }
          }
        }
      }
    }
  }
  .live {
    width: 100%;
    .live-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .live-item {
        width: calc(25% - 12px);
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        cursor: pointer;
        .live-item-img-box {
          width: 100%;
          height: 170px;
          position: relative;
          overflow: hidden;
          .live-item-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tip {
            position: absolute;
            top: 0;
            right: 0;
            width: 54px;
            height: 24px;
            font-size: 12px;
            background: #1d2088;
            border-radius: 0px 10px 0px 10px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:hover .mask {
            opacity: 1;
          }
          .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0;
            transition: opacity 0.5s ease;
            position: absolute;
            top: 0;
            left: 0;
            .mask-icon {
              width: 60px;
              height: 60px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .live-item-content {
          width: 100%;
          box-sizing: border-box;
          padding: 14px 18px;
          .live-content-title {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 30px;
            line-height: 22px;
          }
          .live-time {
            width: 100%;
            padding-top: 14px;
            border-top: 1px solid #ececec;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .notice {
    width: 100%;
    .notice-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .list-box {
        width: calc(50% - 6px);
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 20px 18px 18px;
        box-sizing: border-box;
        .notice-title-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 20px;
          border-bottom: 1px solid #ececec;
          .notice-title {
            font-size: 18px;
            font-weight: 400;
            color: #010101;
          }
          .notice-more {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
            .notice-more-icon {
              width: 6px;
              height: 10px;
              margin-left: 6px;
            }
          }
        }
        .notice-content-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          .n-l-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            line-height: 38px;
            &:hover .n-l-item-title {
              color: #1d2088;
            }
            &:hover .n-l-item-time {
              color: #1d2088;
            }
            .n-l-item-title {
              flex: 1;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
            .n-l-item-time {
              padding-left: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
    }
  }
  .resourcesShare {
    width: 100%;
    padding-bottom: 70px;
    .resourcesShare-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .resourcesShare-item {
        width: calc(50% - 6px);
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 18px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 12px;
        cursor: pointer;
        .resourcesShare-left {
          width: 240px;
          height: 160px;
          border-radius: 10px;
          overflow: hidden;
          margin-right: 18px;
          position: relative;
          .left-img {
            object-fit: cover;
            height: 100%;
          }
          &:hover .mask {
            opacity: 1;
          }
          .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0;
            transition: opacity 0.5s ease;
            position: absolute;
            top: 0;
            left: 0;
            .mask-icon {
              width: 60px;
              height: 60px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .resourcesShare-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .right-title {
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            color: #000000;
          }
          .right-introduction {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
          .right-bottom {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            .bottom-type {
              padding-bottom: 10px;
            }
            .bottom-num-box {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>
